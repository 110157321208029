export const HOME_PAGE = '/'
export const LOGIN_PAGE = '/login'
export const SIGNUP_PAGE = '/signup'
export const PRICING_PAGE = '/pricing'
export const COOKIES = '/legal/cookies-policy'
export const PRIVACY = '/legal/privacy-policy'
export const TERMS = '/legal/terms-of-service'
export const SHORTCUTS_INDEX_PAGE = '/shortcuts'
export const SHORTCUTS_PAGE = '/shortcuts/[slug]'
export const USER_PAGE = '/u/[username]'
export const SETTINGS_PAGE = '/settings'
export const REQUEST_RESET_PASSWORD_PAGE = '/reset-password'
export const RESET_PASSWORD_PAGE = '/reset-password/[token]'
export const UNSUBSCRIBE_PAGE = '/unsubscribe'

export const unprotectedRoutes = [
  HOME_PAGE,
  LOGIN_PAGE,
  SIGNUP_PAGE,
  PRICING_PAGE,
  COOKIES,
  PRIVACY,
  TERMS,
  REQUEST_RESET_PASSWORD_PAGE,
  RESET_PASSWORD_PAGE,
  SHORTCUTS_INDEX_PAGE,
  SHORTCUTS_PAGE,
  UNSUBSCRIBE_PAGE,
  USER_PAGE,
]

export const ONBOARDING_PAGE = '/onboarding'

export const allowedRoutesWhenOnboarding = [
  ONBOARDING_PAGE,
  SETTINGS_PAGE,
  ...unprotectedRoutes,
]
