module.exports = {
  openGraph: {
    type: 'website',
    locale: 'en_EN',
    url: 'https://keyhero.io/',
    site_name: 'Keyhero',
    images: [
      {
        url: 'https://keyhero.io/images/og/home.png',
        width: 1200,
        height: 630,
        alt: 'Keyhero',
        type: 'image/png',
      },
    ],
  },
}
