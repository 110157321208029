import { isServer } from '@utils/helpers'

const AUTH_TOKEN = 'auth-token'

function storeToken(token) {
  if (isServer) return
  localStorage.setItem(AUTH_TOKEN, token)
}

function removeToken() {
  if (isServer) return
  localStorage.removeItem(AUTH_TOKEN)
}

function getToken() {
  if (isServer) return
  return localStorage.getItem(AUTH_TOKEN)
}

function isAuthenticated() {
  if (isServer) return false
  return !!getToken()
}

export { getToken, isAuthenticated, storeToken, removeToken }
