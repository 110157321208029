/* eslint-disable */
// DO NOT EDIT THIS FILE
// Generated by @graphql-codegen/cli using:
// (npm|yarn|pnpm) run generate
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { fetcher } from '@lib/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
// Generated on 10.08.2022 12:24

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
};

/** Inputs for creating a user interface event. */
export type AcceptRecommendationInputs = {
  id: Scalars['ID'];
};

/** Inputs for an admin to invite someone from the waitlist. */
export type AdminInviteWaitlistSubscriberInputs = {
  email: Scalars['String'];
};

/** Generate personal access token payload */
export type AuthenticationPayload = {
  __typename?: 'AuthenticationPayload';
  data?: Maybe<Session>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

/** Checkout session */
export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  url: Scalars['String'];
};

/** Course */
export type Course = {
  __typename?: 'Course';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  public: Scalars['Boolean'];
  shortcuts?: Maybe<Array<Maybe<Shortcut>>>;
  slug: Scalars['String'];
};

/** Course fluency */
export type CourseFluency = {
  __typename?: 'CourseFluency';
  /** The percentage of the user's fluency in the course. */
  fluencyPercent: Scalars['Float'];
  /** The count of the user's shortcuts learned in the course. */
  learnedCount: Scalars['Float'];
  /** The count of the user's shortcuts being reviewed in the course. */
  reviewingCount: Scalars['Float'];
  /** The percentage of the user's shortcuts being reviewed in the course. */
  reviewingPercent: Scalars['Float'];
  /** The count of the user's shortcuts being studied in the course. */
  studyingCount: Scalars['Float'];
  /** The percentage of the user's shortcuts being studied in the course. */
  studyingPercent: Scalars['Float'];
};

/** Inputs for updating the user's daily practice goal. */
export type DailyGoalInputs = {
  dailyGoal: Scalars['Int'];
  onboardingState?: InputMaybe<Scalars['String']>;
};

/** Inputs for updating the user's daily practice reminder. */
export type DailyReminderInputs = {
  dailyReminderHour: Scalars['Int'];
  dailyReminderSend: Scalars['Boolean'];
  onboardingState?: InputMaybe<Scalars['String']>;
};

/** Mutation ErrorType */
export type ErrorType = {
  __typename?: 'ErrorType';
  /** Field on which error occurred. */
  field: Scalars['String'];
  /** Validation error message for corresponding field. */
  message: Scalars['String'];
};

/** Inputs for finishing a practice session. */
export type FinishPracticeSessionInputs = {
  id: Scalars['ID'];
};

export type FinishPracticeSessionPayload = {
  __typename?: 'FinishPracticeSessionPayload';
  currentStreak?: Maybe<Array<Maybe<Streak>>>;
  practiceSession?: Maybe<PracticeSession>;
};

/** Inputs for creating a personal access token. */
export type GeneratePersonalAccessTokenInputs = {
  expires?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Authentication payload */
export type GeneratePersonalAccessTokenPayload = {
  __typename?: 'GeneratePersonalAccessTokenPayload';
  disappearingToken: Scalars['String'];
  personalAccessToken: PersonalAccessToken;
};

/** Key */
export type Key = {
  __typename?: 'Key';
  code: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  keyCode: Scalars['Int'];
  name: Scalars['String'];
};

/** Inputs for updating the user's name and username. */
export type NamesInputs = {
  name?: InputMaybe<Scalars['String']>;
  onboardingState?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

/** Personal access token */
export type PersonalAccessToken = {
  __typename?: 'PersonalAccessToken';
  expires?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Practice counts */
export type PracticeCounts = {
  __typename?: 'PracticeCounts';
  /** The amount of shortcuts due for practice. */
  due: Scalars['Int'];
  /** The amount of new shortcuts to practice. */
  new: Scalars['Int'];
  /** The amount of shortcuts waiting to be added to new. */
  pending: Scalars['Int'];
  /** The amount of shortcuts being studied. */
  studying: Scalars['Int'];
};

/** Inputs for updating a practiced user shortcut. */
export type PracticeInputs = {
  completionSeconds: Scalars['Float'];
  due: Scalars['String'];
  efactor: Scalars['Float'];
  id: Scalars['ID'];
  interval: Scalars['Float'];
  review: Scalars['Int'];
  score: Scalars['Int'];
  timesSeen: Scalars['Int'];
};

/** Practice response */
export type PracticeResponse = {
  __typename?: 'PracticeResponse';
  practiceSession: PracticeSession;
  userShortcuts?: Maybe<Array<Maybe<UserShortcut>>>;
};

/** Practice Session */
export type PracticeSession = {
  __typename?: 'PracticeSession';
  finished: Scalars['Boolean'];
  id: Scalars['ID'];
  insertedAt: Scalars['String'];
};

/** Recommendation */
export type Recommendation = {
  __typename?: 'Recommendation';
  id: Scalars['ID'];
  shortcut: Shortcut;
  trigger: Trigger;
  type: Scalars['String'];
  user: User;
  userInterfaceEvent: UserInterfaceEvent;
  userShortcut: UserShortcut;
};

/** Inputs for Registering a new user. */
export type RegistrationInputs = {
  email: Scalars['String'];
  inviteCode: Scalars['String'];
  password: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

/** Inputs for reporting an issue with a shortcut. */
export type ReportShortcutInputs = {
  browserName?: InputMaybe<Scalars['String']>;
  browserVersion?: InputMaybe<Scalars['String']>;
  osName?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  reportType: Scalars['String'];
  userShortcutId: Scalars['ID'];
};

/** Inputs for requesting a password reset. */
export type RequestResetPasswordInputs = {
  email: Scalars['String'];
};

export type RequestResetPasswordPayload = {
  __typename?: 'RequestResetPasswordPayload';
  email: Scalars['String'];
};

/** Inputs for resetting a password. */
export type ResetPasswordInputs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  success: Scalars['String'];
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Accept a recommendation */
  acceptRecommendation?: Maybe<Recommendation>;
  /** Admin: Invite a waitlist subscriber to Keyhero */
  adminInviteWaitlistSubscriber?: Maybe<WaitlistSubscriber>;
  /** Create or update user shortcuts */
  createOrUpdateUserShortcuts?: Maybe<UserShortcutsPayload>;
  /** Create a user course */
  createUserCourse?: Maybe<UserCourse>;
  /** Create a user interface event */
  createUserInterfaceEvent?: Maybe<UserInterfaceEvent>;
  /** Finish a practice session */
  finishPracticeSession?: Maybe<FinishPracticeSessionPayload>;
  /** Create a personal access token for accessing the Keyhero GraphQL API */
  generatePersonalAccessToken?: Maybe<GeneratePersonalAccessTokenPayload>;
  /** Join the waitlist */
  joinWaitlist?: Maybe<WaitlistSubscriber>;
  /** Practice a shortcut */
  practiceShortcut?: Maybe<UserShortcut>;
  /** Remove user shortcut */
  removeUserShortcut?: Maybe<UserShortcut>;
  /** Report an issue with a shortcut */
  reportShortcut?: Maybe<ShortcutReport>;
  /** Request to reset password */
  requestResetPassword?: Maybe<RequestResetPasswordPayload>;
  /** Reset password */
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Sign in a user */
  signin?: Maybe<AuthenticationPayload>;
  /** Create a user account */
  signup?: Maybe<AuthenticationPayload>;
  /** Start a checkout session */
  startCheckoutSession?: Maybe<CheckoutSession>;
  unsubscribe?: Maybe<UnsubscribePayload>;
  /** Update a user's daily practice goal settings */
  updateDailyGoal?: Maybe<User>;
  /** Update a user's daily practice reminder settings */
  updateDailyReminder?: Maybe<User>;
  /** Update a user's name */
  updateName?: Maybe<User>;
  /** Update a user's name and username */
  updateNames?: Maybe<User>;
  /** Update user shortcut test type */
  updateUserShortcutTestType?: Maybe<UserShortcut>;
  /** Update a user's username */
  updateUsername?: Maybe<User>;
};


export type RootMutationTypeAcceptRecommendationArgs = {
  inputs: AcceptRecommendationInputs;
};


export type RootMutationTypeAdminInviteWaitlistSubscriberArgs = {
  inputs: AdminInviteWaitlistSubscriberInputs;
};


export type RootMutationTypeCreateOrUpdateUserShortcutsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UserShortcutInputs>>>;
};


export type RootMutationTypeCreateUserCourseArgs = {
  inputs: UserCourseInputs;
};


export type RootMutationTypeCreateUserInterfaceEventArgs = {
  inputs: UserInterfaceEventInputs;
};


export type RootMutationTypeFinishPracticeSessionArgs = {
  inputs: FinishPracticeSessionInputs;
};


export type RootMutationTypeGeneratePersonalAccessTokenArgs = {
  inputs: GeneratePersonalAccessTokenInputs;
};


export type RootMutationTypeJoinWaitlistArgs = {
  inputs: WaitlistInputs;
};


export type RootMutationTypePracticeShortcutArgs = {
  inputs: PracticeInputs;
};


export type RootMutationTypeRemoveUserShortcutArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeReportShortcutArgs = {
  inputs: ReportShortcutInputs;
};


export type RootMutationTypeRequestResetPasswordArgs = {
  inputs: RequestResetPasswordInputs;
};


export type RootMutationTypeResetPasswordArgs = {
  inputs: ResetPasswordInputs;
};


export type RootMutationTypeSigninArgs = {
  inputs: SigninInputs;
};


export type RootMutationTypeSignupArgs = {
  inputs: RegistrationInputs;
};


export type RootMutationTypeStartCheckoutSessionArgs = {
  inputs: StartCheckoutSessionInputs;
};


export type RootMutationTypeUnsubscribeArgs = {
  inputs: UnsubscribeInputs;
};


export type RootMutationTypeUpdateDailyGoalArgs = {
  inputs: DailyGoalInputs;
};


export type RootMutationTypeUpdateDailyReminderArgs = {
  inputs: DailyReminderInputs;
};


export type RootMutationTypeUpdateNameArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type RootMutationTypeUpdateNamesArgs = {
  inputs: NamesInputs;
};


export type RootMutationTypeUpdateUserShortcutTestTypeArgs = {
  inputs: UserShortcutUpdateTestTypeInputs;
};


export type RootMutationTypeUpdateUsernameArgs = {
  username: Scalars['String'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  courseUserShortcuts?: Maybe<Array<Maybe<UserShortcut>>>;
  /** Get a list of courses */
  courses?: Maybe<Array<Maybe<Course>>>;
  /** Get a course */
  getCourse?: Maybe<Course>;
  /** Get a user's course fluency */
  getCourseFluency?: Maybe<CourseFluency>;
  /** Get the latest shortcut practiced on Keyhero */
  getLatestShortcutPractice?: Maybe<ShortcutPractice>;
  /** Get a user's personal access tokens */
  getPersonalAccessTokens?: Maybe<Array<Maybe<PersonalAccessToken>>>;
  /** Get unprocessed recommendations */
  getUnprocessedRecommendations?: Maybe<Array<Maybe<Recommendation>>>;
  /** Get a user */
  getUser?: Maybe<User>;
  /** Get a list of a user's courses */
  getUserCourses?: Maybe<Array<Maybe<UserCourse>>>;
  /** Get users */
  getUsers?: Maybe<Array<Maybe<User>>>;
  /** Get the currently signed-in user */
  me?: Maybe<User>;
  /** Get keyboard shortcuts to practice. */
  practice?: Maybe<PracticeResponse>;
  /** Get practice counts: due now, new. */
  practiceCounts?: Maybe<PracticeCounts>;
  /** Get a list of a courses a user hasn't added */
  unsubscribedCourses?: Maybe<Array<Maybe<Course>>>;
  /** Get a list of a user's courses */
  userCourses?: Maybe<Array<Maybe<UserCourse>>>;
};


export type RootQueryTypeCourseUserShortcutsArgs = {
  courseId: Scalars['ID'];
};


export type RootQueryTypeGetCourseArgs = {
  slug: Scalars['String'];
};


export type RootQueryTypeGetCourseFluencyArgs = {
  courseId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RootQueryTypeGetUserArgs = {
  username: Scalars['String'];
};


export type RootQueryTypeGetUserCoursesArgs = {
  username: Scalars['String'];
};


export type RootQueryTypeGetUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  /** Receive notifications when a user has new recommendations. */
  recommendationsUpdated?: Maybe<User>;
};

/** User session */
export type Session = {
  __typename?: 'Session';
  /** A user's session token. */
  token: Scalars['String'];
  /** The currently authenticated user. */
  user: User;
};

/** Shortcut */
export type Shortcut = {
  __typename?: 'Shortcut';
  course: Course;
  id: Scalars['ID'];
  name: Scalars['String'];
  shortcutGroup: ShortcutGroup;
  shortcutKeys?: Maybe<Array<Maybe<ShortcutKey>>>;
  testType: Scalars['String'];
};


/** Shortcut */
export type ShortcutShortcutKeysArgs = {
  layout?: InputMaybe<Scalars['String']>;
};

/** Shortcut Group */
export type ShortcutGroup = {
  __typename?: 'ShortcutGroup';
  icon?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Shortcut Key */
export type ShortcutKey = {
  __typename?: 'ShortcutKey';
  key: Key;
  layout: Scalars['String'];
  position: Scalars['Int'];
  shortcutCombinationId: Scalars['ID'];
};

/** Shortcut Practice */
export type ShortcutPractice = {
  __typename?: 'ShortcutPractice';
  id: Scalars['ID'];
  insertedAt: Scalars['String'];
  userShortcut: UserShortcut;
};

/** Shortcut Report */
export type ShortcutReport = {
  __typename?: 'ShortcutReport';
  browserName?: Maybe<Scalars['String']>;
  browserVersion?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  osName?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
  shortcut: Shortcut;
  user: User;
  userShortcut: UserShortcut;
};

/** Inputs for signing in a user. */
export type SigninInputs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Inputs for starting a checkout session. */
export type StartCheckoutSessionInputs = {
  planName?: InputMaybe<Scalars['String']>;
};

/** Streak */
export type Streak = {
  __typename?: 'Streak';
  days: Scalars['Int'];
  id: Scalars['ID'];
  insertedAt: Scalars['String'];
  user?: Maybe<User>;
};

/** Trigger */
export type Trigger = {
  __typename?: 'Trigger';
  context: Scalars['String'];
  course: Course;
  id: Scalars['ID'];
  shortcut: Shortcut;
};

/** Inputs for unsubscribing from an email. */
export type UnsubscribeInputs = {
  email: Scalars['String'];
  emailType: Scalars['String'];
};

export type UnsubscribePayload = {
  __typename?: 'UnsubscribePayload';
  success: Scalars['String'];
};

/** User */
export type User = {
  __typename?: 'User';
  admin: Scalars['Boolean'];
  currentStreak?: Maybe<Array<Maybe<Streak>>>;
  dailyGoal: Scalars['Int'];
  dailyReminderHour: Scalars['Int'];
  dailyReminderSend: Scalars['Boolean'];
  /** The user's email address. */
  email: Scalars['String'];
  gravatar?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  onboardingState: Scalars['String'];
  shortcutsLearnedCount: Scalars['Int'];
  subscriptionActive: Scalars['Boolean'];
  timezone: Scalars['String'];
  username: Scalars['String'];
};

/** User course */
export type UserCourse = {
  __typename?: 'UserCourse';
  course: Course;
  user: User;
};

/** Inputs for creating or updating a user course. */
export type UserCourseInputs = {
  courseId: Scalars['ID'];
};

/** User interface event */
export type UserInterfaceEvent = {
  __typename?: 'UserInterfaceEvent';
  context: Scalars['String'];
  id: Scalars['ID'];
  subtype: Scalars['String'];
  type: Scalars['String'];
  webDomSelector?: Maybe<Scalars['String']>;
  webUiEvent?: Maybe<Scalars['Json']>;
};

/** Inputs for creating a user interface event. */
export type UserInterfaceEventInputs = {
  context: Scalars['String'];
  subtype: Scalars['String'];
  type: Scalars['String'];
  webDomSelector?: InputMaybe<Scalars['String']>;
  webUiEvent?: InputMaybe<Scalars['Json']>;
};

/** User shortcut */
export type UserShortcut = {
  __typename?: 'UserShortcut';
  courseId: Scalars['ID'];
  /** When the shortcut is due for practice. */
  due: Scalars['String'];
  /** The easiness factor for repeating the shortcut. */
  efactor: Scalars['Float'];
  id: Scalars['ID'];
  /** The interval of time until the shortcut is due for practice. */
  interval: Scalars['Float'];
  /** The number of the most recent review the user completed. */
  review: Scalars['Int'];
  /** The most recent answer score for the user for this shortcut. */
  score: Scalars['Int'];
  shortcut: Shortcut;
  shortcutId: Scalars['ID'];
  /** If the user is currently studying this shortcut. */
  studying: Scalars['Boolean'];
  /** The test type the user prefers, if any. */
  testTypeOverride?: Maybe<Scalars['String']>;
  /** How many times the user has seen this shortcut. */
  timesSeen: Scalars['Int'];
  userId: Scalars['ID'];
};

/** Inputs for creating or updating a user shortcut. */
export type UserShortcutInputs = {
  courseId: Scalars['ID'];
  shortcutId: Scalars['ID'];
  studying: Scalars['Boolean'];
};

/** Inputs for updating a user shortcut's test type. */
export type UserShortcutUpdateTestTypeInputs = {
  id: Scalars['ID'];
  testTypeOverride: Scalars['String'];
};

export type UserShortcutsPayload = {
  __typename?: 'UserShortcutsPayload';
  data?: Maybe<Array<Maybe<UserShortcut>>>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

/** Inputs for subscribing to the waitlist. */
export type WaitlistInputs = {
  email: Scalars['String'];
};

/** Waitlist subscriber */
export type WaitlistSubscriber = {
  __typename?: 'WaitlistSubscriber';
  convertkitSubscriptionId: Scalars['Int'];
  email: Scalars['String'];
  id: Scalars['ID'];
  inviteCode?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type CourseUserShortcutsVariables = Exact<{
  courseId: Scalars['ID'];
  layout: Scalars['String'];
}>;


export type CourseUserShortcuts = { __typename?: 'RootQueryType', courseUserShortcuts?: Array<{ __typename?: 'UserShortcut', id: string, due: string, efactor: number, interval: number, review: number, score: number, studying: boolean, timesSeen: number, shortcut: { __typename?: 'Shortcut', id: string, name: string, testType: string, course: { __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean }, shortcutGroup: { __typename?: 'ShortcutGroup', icon?: string | null, name: string }, shortcutKeys?: Array<{ __typename?: 'ShortcutKey', layout: string, position: number, shortcutCombinationId: string, key: { __typename?: 'Key', code: string, icon?: string | null, key: string, keyCode: number, name: string } } | null> | null } } | null> | null };

export type CoursesVariables = Exact<{ [key: string]: never; }>;


export type Courses = { __typename?: 'RootQueryType', courses?: Array<{ __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean, slug: string } | null> | null };

export type CreateOrUpdateUserShortcutsVariables = Exact<{
  inputs?: InputMaybe<Array<UserShortcutInputs> | UserShortcutInputs>;
}>;


export type CreateOrUpdateUserShortcuts = { __typename?: 'RootMutationType', res?: { __typename?: 'UserShortcutsPayload', data?: Array<{ __typename?: 'UserShortcut', id: string, shortcutId: string, studying: boolean } | null> | null } | null };

export type CreateUserCourseVariables = Exact<{
  inputs: UserCourseInputs;
}>;


export type CreateUserCourse = { __typename?: 'RootMutationType', res?: { __typename?: 'UserCourse', course: { __typename?: 'Course', id: string, slug: string } } | null };

export type CurrentUserVariables = Exact<{ [key: string]: never; }>;


export type CurrentUser = { __typename?: 'RootQueryType', me?: { __typename?: 'User', id: string, admin: boolean, dailyGoal: number, dailyReminderHour: number, dailyReminderSend: boolean, email: string, gravatar?: string | null, name?: string | null, onboardingState: string, shortcutsLearnedCount: number, subscriptionActive: boolean, timezone: string, username: string, currentStreak?: Array<{ __typename?: 'Streak', id: string, days: number, insertedAt: string } | null> | null } | null };

export type FinishPracticeSessionVariables = Exact<{
  inputs: FinishPracticeSessionInputs;
}>;


export type FinishPracticeSession = { __typename?: 'RootMutationType', res?: { __typename?: 'FinishPracticeSessionPayload', practiceSession?: { __typename?: 'PracticeSession', id: string, finished: boolean, insertedAt: string } | null, currentStreak?: Array<{ __typename?: 'Streak', id: string, days: number, insertedAt: string } | null> | null } | null };

export type GeneratePersonalAccessTokenVariables = Exact<{
  inputs: GeneratePersonalAccessTokenInputs;
}>;


export type GeneratePersonalAccessToken = { __typename?: 'RootMutationType', res?: { __typename?: 'GeneratePersonalAccessTokenPayload', disappearingToken: string, personalAccessToken: { __typename?: 'PersonalAccessToken', id: string, expires?: string | null, name: string } } | null };

export type GetCourseVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetCourse = { __typename?: 'RootQueryType', getCourse?: { __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean, slug: string, shortcuts?: Array<{ __typename?: 'Shortcut', id: string, name: string, testType: string, course: { __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean }, shortcutGroup: { __typename?: 'ShortcutGroup', icon?: string | null, name: string }, shortcutKeys?: Array<{ __typename?: 'ShortcutKey', layout: string, position: number, shortcutCombinationId: string, key: { __typename?: 'Key', code: string, icon?: string | null, key: string, keyCode: number, name: string } } | null> | null } | null> | null } | null };

export type GetCourseFluencyVariables = Exact<{
  courseId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type GetCourseFluency = { __typename?: 'RootQueryType', getCourseFluency?: { __typename?: 'CourseFluency', fluencyPercent: number, learnedCount: number, reviewingPercent: number, reviewingCount: number, studyingCount: number, studyingPercent: number } | null };

export type GetLatestShortcutPracticeVariables = Exact<{
  layout: Scalars['String'];
}>;


export type GetLatestShortcutPractice = { __typename?: 'RootQueryType', getLatestShortcutPractice?: { __typename?: 'ShortcutPractice', id: string, insertedAt: string, userShortcut: { __typename?: 'UserShortcut', shortcut: { __typename?: 'Shortcut', id: string, name: string, testType: string, course: { __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean }, shortcutGroup: { __typename?: 'ShortcutGroup', icon?: string | null, name: string }, shortcutKeys?: Array<{ __typename?: 'ShortcutKey', layout: string, position: number, shortcutCombinationId: string, key: { __typename?: 'Key', code: string, icon?: string | null, key: string, keyCode: number, name: string } } | null> | null } } } | null };

export type GetPersonalAccessTokensVariables = Exact<{ [key: string]: never; }>;


export type GetPersonalAccessTokens = { __typename?: 'RootQueryType', getPersonalAccessTokens?: Array<{ __typename?: 'PersonalAccessToken', id: string, expires?: string | null, name: string } | null> | null };

export type GetPracticeCountsVariables = Exact<{ [key: string]: never; }>;


export type GetPracticeCounts = { __typename?: 'RootQueryType', practiceCounts?: { __typename?: 'PracticeCounts', due: number, new: number, pending: number, studying: number } | null };

export type GetUserVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetUser = { __typename?: 'RootQueryType', getUser?: { __typename?: 'User', id: string, gravatar?: string | null, name?: string | null, shortcutsLearnedCount: number, username: string, currentStreak?: Array<{ __typename?: 'Streak', id: string, days: number, insertedAt: string } | null> | null } | null };

export type GetUserCoursesVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetUserCourses = { __typename?: 'RootQueryType', getUserCourses?: Array<{ __typename?: 'UserCourse', course: { __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean, slug: string } } | null> | null };

export type GetUsersVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type GetUsers = { __typename?: 'RootQueryType', getUsers?: Array<{ __typename?: 'User', id: string, gravatar?: string | null, username: string, currentStreak?: Array<{ __typename?: 'Streak', id: string, days: number, insertedAt: string } | null> | null } | null> | null };

export type JoinWaitlistVariables = Exact<{
  inputs: WaitlistInputs;
}>;


export type JoinWaitlist = { __typename?: 'RootMutationType', res?: { __typename?: 'WaitlistSubscriber', id: string, convertkitSubscriptionId: number, email: string } | null };

export type PracticeVariables = Exact<{
  layout: Scalars['String'];
}>;


export type Practice = { __typename?: 'RootQueryType', practice?: { __typename?: 'PracticeResponse', practiceSession: { __typename?: 'PracticeSession', id: string, insertedAt: string, finished: boolean }, userShortcuts?: Array<{ __typename?: 'UserShortcut', id: string, courseId: string, due: string, efactor: number, interval: number, review: number, score: number, studying: boolean, testTypeOverride?: string | null, timesSeen: number, shortcutId: string, userId: string, shortcut: { __typename?: 'Shortcut', id: string, name: string, testType: string, course: { __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean, slug: string }, shortcutGroup: { __typename?: 'ShortcutGroup', icon?: string | null, name: string }, shortcutKeys?: Array<{ __typename?: 'ShortcutKey', layout: string, position: number, shortcutCombinationId: string, key: { __typename?: 'Key', code: string, icon?: string | null, key: string, keyCode: number, name: string } } | null> | null } } | null> | null } | null };

export type PracticeShortcutVariables = Exact<{
  inputs: PracticeInputs;
}>;


export type PracticeShortcut = { __typename?: 'RootMutationType', res?: { __typename?: 'UserShortcut', id: string, due: string, efactor: number, interval: number, review: number, score: number, studying: boolean, testTypeOverride?: string | null, timesSeen: number } | null };

export type RemoveUserShortcutVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUserShortcut = { __typename?: 'RootMutationType', res?: { __typename?: 'UserShortcut', id: string, due: string, efactor: number, interval: number, review: number, score: number, studying: boolean, timesSeen: number } | null };

export type ReportShortcutVariables = Exact<{
  inputs: ReportShortcutInputs;
}>;


export type ReportShortcut = { __typename?: 'RootMutationType', res?: { __typename?: 'ShortcutReport', id: string } | null };

export type RequestResetPasswordVariables = Exact<{
  inputs: RequestResetPasswordInputs;
}>;


export type RequestResetPassword = { __typename?: 'RootMutationType', res?: { __typename?: 'RequestResetPasswordPayload', email: string } | null };

export type ResetPasswordVariables = Exact<{
  inputs: ResetPasswordInputs;
}>;


export type ResetPassword = { __typename?: 'RootMutationType', res?: { __typename?: 'ResetPasswordPayload', success: string } | null };

export type SigninVariables = Exact<{
  inputs: SigninInputs;
}>;


export type Signin = { __typename?: 'RootMutationType', res?: { __typename?: 'AuthenticationPayload', data?: { __typename?: 'Session', token: string, user: { __typename?: 'User', id: string, admin: boolean, dailyGoal: number, dailyReminderHour: number, dailyReminderSend: boolean, email: string, gravatar?: string | null, name?: string | null, onboardingState: string, shortcutsLearnedCount: number, subscriptionActive: boolean, timezone: string, username: string, currentStreak?: Array<{ __typename?: 'Streak', id: string, days: number, insertedAt: string } | null> | null } } | null, errors?: Array<{ __typename?: 'ErrorType', field: string, message: string } | null> | null } | null };

export type SignupVariables = Exact<{
  inputs: RegistrationInputs;
}>;


export type Signup = { __typename?: 'RootMutationType', res?: { __typename?: 'AuthenticationPayload', data?: { __typename?: 'Session', token: string, user: { __typename?: 'User', id: string, admin: boolean, dailyGoal: number, dailyReminderHour: number, dailyReminderSend: boolean, email: string, gravatar?: string | null, name?: string | null, onboardingState: string, shortcutsLearnedCount: number, subscriptionActive: boolean, timezone: string, username: string, currentStreak?: Array<{ __typename?: 'Streak', id: string, days: number, insertedAt: string } | null> | null } } | null, errors?: Array<{ __typename?: 'ErrorType', field: string, message: string } | null> | null } | null };

export type StartCheckoutSessionVariables = Exact<{
  inputs: StartCheckoutSessionInputs;
}>;


export type StartCheckoutSession = { __typename?: 'RootMutationType', res?: { __typename?: 'CheckoutSession', url: string } | null };

export type UnsubscribeVariables = Exact<{
  inputs: UnsubscribeInputs;
}>;


export type Unsubscribe = { __typename?: 'RootMutationType', res?: { __typename?: 'UnsubscribePayload', success: string } | null };

export type UnsubscribedCoursesVariables = Exact<{ [key: string]: never; }>;


export type UnsubscribedCourses = { __typename?: 'RootQueryType', unsubscribedCourses?: Array<{ __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean, slug: string } | null> | null };

export type UpdateDailyGoalVariables = Exact<{
  inputs: DailyGoalInputs;
}>;


export type UpdateDailyGoal = { __typename?: 'RootMutationType', res?: { __typename?: 'User', id: string, dailyGoal: number, dailyReminderHour: number, dailyReminderSend: boolean, email: string, gravatar?: string | null, name?: string | null, onboardingState: string, timezone: string, username: string } | null };

export type UpdateDailyReminderVariables = Exact<{
  inputs: DailyReminderInputs;
}>;


export type UpdateDailyReminder = { __typename?: 'RootMutationType', res?: { __typename?: 'User', id: string, dailyGoal: number, dailyReminderHour: number, dailyReminderSend: boolean, email: string, gravatar?: string | null, name?: string | null, onboardingState: string, timezone: string, username: string } | null };

export type UpdateNameVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateName = { __typename?: 'RootMutationType', res?: { __typename?: 'User', id: string, dailyGoal: number, dailyReminderHour: number, dailyReminderSend: boolean, email: string, gravatar?: string | null, name?: string | null, onboardingState: string, timezone: string, username: string } | null };

export type UpdateNamesVariables = Exact<{
  inputs: NamesInputs;
}>;


export type UpdateNames = { __typename?: 'RootMutationType', res?: { __typename?: 'User', id: string, dailyGoal: number, dailyReminderHour: number, dailyReminderSend: boolean, email: string, gravatar?: string | null, name?: string | null, onboardingState: string, timezone: string, username: string } | null };

export type UpdateUserShortcutTestTypeVariables = Exact<{
  inputs: UserShortcutUpdateTestTypeInputs;
}>;


export type UpdateUserShortcutTestType = { __typename?: 'RootMutationType', res?: { __typename?: 'UserShortcut', id: string, due: string, efactor: number, interval: number, review: number, score: number, studying: boolean, testTypeOverride?: string | null, timesSeen: number } | null };

export type UpdateUsernameVariables = Exact<{
  username: Scalars['String'];
}>;


export type UpdateUsername = { __typename?: 'RootMutationType', res?: { __typename?: 'User', id: string, dailyGoal: number, dailyReminderHour: number, dailyReminderSend: boolean, email: string, gravatar?: string | null, name?: string | null, onboardingState: string, timezone: string, username: string } | null };

export type UserCoursesVariables = Exact<{ [key: string]: never; }>;


export type UserCourses = { __typename?: 'RootQueryType', userCourses?: Array<{ __typename?: 'UserCourse', course: { __typename?: 'Course', id: string, icon?: string | null, name: string, public: boolean, slug: string }, user: { __typename?: 'User', id: string } } | null> | null };


export const CourseUserShortcutsDocument = `
    query CourseUserShortcuts($courseId: ID!, $layout: String!) {
  courseUserShortcuts(courseId: $courseId) {
    id
    due
    efactor
    interval
    review
    score
    studying
    timesSeen
    shortcut {
      id
      name
      testType
      course {
        id
        icon
        name
        public
      }
      shortcutGroup {
        icon
        name
      }
      shortcutKeys(layout: $layout) {
        key {
          code
          icon
          key
          keyCode
          name
        }
        layout
        position
        shortcutCombinationId
      }
    }
  }
}
    `;
export const useCourseUserShortcuts = <
      TData = CourseUserShortcuts,
      TError = unknown
    >(
      variables: CourseUserShortcutsVariables,
      options?: UseQueryOptions<CourseUserShortcuts, TError, TData>
    ) =>
    useQuery<CourseUserShortcuts, TError, TData>(
      ['CourseUserShortcuts', variables],
      fetcher<CourseUserShortcuts, CourseUserShortcutsVariables>(CourseUserShortcutsDocument, variables),
      options
    );
useCourseUserShortcuts.document = CourseUserShortcutsDocument;


useCourseUserShortcuts.getKey = (variables: CourseUserShortcutsVariables) => ['CourseUserShortcuts', variables];
;

useCourseUserShortcuts.fetcher = (variables: CourseUserShortcutsVariables, options?: RequestInit['headers']) => fetcher<CourseUserShortcuts, CourseUserShortcutsVariables>(CourseUserShortcutsDocument, variables, options);
export const CoursesDocument = `
    query Courses {
  courses {
    id
    icon
    name
    public
    slug
  }
}
    `;
export const useCourses = <
      TData = Courses,
      TError = unknown
    >(
      variables?: CoursesVariables,
      options?: UseQueryOptions<Courses, TError, TData>
    ) =>
    useQuery<Courses, TError, TData>(
      variables === undefined ? ['Courses'] : ['Courses', variables],
      fetcher<Courses, CoursesVariables>(CoursesDocument, variables),
      options
    );
useCourses.document = CoursesDocument;


useCourses.getKey = (variables?: CoursesVariables) => variables === undefined ? ['Courses'] : ['Courses', variables];
;

useCourses.fetcher = (variables?: CoursesVariables, options?: RequestInit['headers']) => fetcher<Courses, CoursesVariables>(CoursesDocument, variables, options);
export const CreateOrUpdateUserShortcutsDocument = `
    mutation createOrUpdateUserShortcuts($inputs: [UserShortcutInputs!]) {
  res: createOrUpdateUserShortcuts(inputs: $inputs) {
    data {
      id
      shortcutId
      studying
    }
  }
}
    `;
export const useCreateOrUpdateUserShortcuts = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOrUpdateUserShortcuts, TError, CreateOrUpdateUserShortcutsVariables, TContext>) =>
    useMutation<CreateOrUpdateUserShortcuts, TError, CreateOrUpdateUserShortcutsVariables, TContext>(
      ['createOrUpdateUserShortcuts'],
      (variables?: CreateOrUpdateUserShortcutsVariables) => fetcher<CreateOrUpdateUserShortcuts, CreateOrUpdateUserShortcutsVariables>(CreateOrUpdateUserShortcutsDocument, variables)(),
      options
    );
useCreateOrUpdateUserShortcuts.fetcher = (variables?: CreateOrUpdateUserShortcutsVariables, options?: RequestInit['headers']) => fetcher<CreateOrUpdateUserShortcuts, CreateOrUpdateUserShortcutsVariables>(CreateOrUpdateUserShortcutsDocument, variables, options);
export const CreateUserCourseDocument = `
    mutation createUserCourse($inputs: UserCourseInputs!) {
  res: createUserCourse(inputs: $inputs) {
    course {
      id
      slug
    }
  }
}
    `;
export const useCreateUserCourse = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserCourse, TError, CreateUserCourseVariables, TContext>) =>
    useMutation<CreateUserCourse, TError, CreateUserCourseVariables, TContext>(
      ['createUserCourse'],
      (variables?: CreateUserCourseVariables) => fetcher<CreateUserCourse, CreateUserCourseVariables>(CreateUserCourseDocument, variables)(),
      options
    );
useCreateUserCourse.fetcher = (variables: CreateUserCourseVariables, options?: RequestInit['headers']) => fetcher<CreateUserCourse, CreateUserCourseVariables>(CreateUserCourseDocument, variables, options);
export const CurrentUserDocument = `
    query CurrentUser {
  me {
    id
    admin
    currentStreak {
      id
      days
      insertedAt
    }
    dailyGoal
    dailyReminderHour
    dailyReminderSend
    email
    gravatar
    name
    onboardingState
    shortcutsLearnedCount
    subscriptionActive
    timezone
    username
  }
}
    `;
export const useCurrentUser = <
      TData = CurrentUser,
      TError = unknown
    >(
      variables?: CurrentUserVariables,
      options?: UseQueryOptions<CurrentUser, TError, TData>
    ) =>
    useQuery<CurrentUser, TError, TData>(
      variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables],
      fetcher<CurrentUser, CurrentUserVariables>(CurrentUserDocument, variables),
      options
    );
useCurrentUser.document = CurrentUserDocument;


useCurrentUser.getKey = (variables?: CurrentUserVariables) => variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables];
;

useCurrentUser.fetcher = (variables?: CurrentUserVariables, options?: RequestInit['headers']) => fetcher<CurrentUser, CurrentUserVariables>(CurrentUserDocument, variables, options);
export const FinishPracticeSessionDocument = `
    mutation finishPracticeSession($inputs: FinishPracticeSessionInputs!) {
  res: finishPracticeSession(inputs: $inputs) {
    practiceSession {
      id
      finished
      insertedAt
    }
    currentStreak {
      id
      days
      insertedAt
    }
  }
}
    `;
export const useFinishPracticeSession = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<FinishPracticeSession, TError, FinishPracticeSessionVariables, TContext>) =>
    useMutation<FinishPracticeSession, TError, FinishPracticeSessionVariables, TContext>(
      ['finishPracticeSession'],
      (variables?: FinishPracticeSessionVariables) => fetcher<FinishPracticeSession, FinishPracticeSessionVariables>(FinishPracticeSessionDocument, variables)(),
      options
    );
useFinishPracticeSession.fetcher = (variables: FinishPracticeSessionVariables, options?: RequestInit['headers']) => fetcher<FinishPracticeSession, FinishPracticeSessionVariables>(FinishPracticeSessionDocument, variables, options);
export const GeneratePersonalAccessTokenDocument = `
    mutation GeneratePersonalAccessToken($inputs: GeneratePersonalAccessTokenInputs!) {
  res: generatePersonalAccessToken(inputs: $inputs) {
    personalAccessToken {
      id
      expires
      name
    }
    disappearingToken
  }
}
    `;
export const useGeneratePersonalAccessToken = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GeneratePersonalAccessToken, TError, GeneratePersonalAccessTokenVariables, TContext>) =>
    useMutation<GeneratePersonalAccessToken, TError, GeneratePersonalAccessTokenVariables, TContext>(
      ['GeneratePersonalAccessToken'],
      (variables?: GeneratePersonalAccessTokenVariables) => fetcher<GeneratePersonalAccessToken, GeneratePersonalAccessTokenVariables>(GeneratePersonalAccessTokenDocument, variables)(),
      options
    );
useGeneratePersonalAccessToken.fetcher = (variables: GeneratePersonalAccessTokenVariables, options?: RequestInit['headers']) => fetcher<GeneratePersonalAccessToken, GeneratePersonalAccessTokenVariables>(GeneratePersonalAccessTokenDocument, variables, options);
export const GetCourseDocument = `
    query GetCourse($slug: String!) {
  getCourse(slug: $slug) {
    id
    icon
    name
    public
    slug
    shortcuts {
      id
      name
      testType
      course {
        id
        icon
        name
        public
      }
      shortcutGroup {
        icon
        name
      }
      shortcutKeys {
        key {
          code
          icon
          key
          keyCode
          name
        }
        layout
        position
        shortcutCombinationId
      }
    }
  }
}
    `;
export const useGetCourse = <
      TData = GetCourse,
      TError = unknown
    >(
      variables: GetCourseVariables,
      options?: UseQueryOptions<GetCourse, TError, TData>
    ) =>
    useQuery<GetCourse, TError, TData>(
      ['GetCourse', variables],
      fetcher<GetCourse, GetCourseVariables>(GetCourseDocument, variables),
      options
    );
useGetCourse.document = GetCourseDocument;


useGetCourse.getKey = (variables: GetCourseVariables) => ['GetCourse', variables];
;

useGetCourse.fetcher = (variables: GetCourseVariables, options?: RequestInit['headers']) => fetcher<GetCourse, GetCourseVariables>(GetCourseDocument, variables, options);
export const GetCourseFluencyDocument = `
    query GetCourseFluency($courseId: ID!, $userId: ID!) {
  getCourseFluency(courseId: $courseId, userId: $userId) {
    fluencyPercent
    learnedCount
    reviewingPercent
    reviewingCount
    studyingCount
    studyingPercent
  }
}
    `;
export const useGetCourseFluency = <
      TData = GetCourseFluency,
      TError = unknown
    >(
      variables: GetCourseFluencyVariables,
      options?: UseQueryOptions<GetCourseFluency, TError, TData>
    ) =>
    useQuery<GetCourseFluency, TError, TData>(
      ['GetCourseFluency', variables],
      fetcher<GetCourseFluency, GetCourseFluencyVariables>(GetCourseFluencyDocument, variables),
      options
    );
useGetCourseFluency.document = GetCourseFluencyDocument;


useGetCourseFluency.getKey = (variables: GetCourseFluencyVariables) => ['GetCourseFluency', variables];
;

useGetCourseFluency.fetcher = (variables: GetCourseFluencyVariables, options?: RequestInit['headers']) => fetcher<GetCourseFluency, GetCourseFluencyVariables>(GetCourseFluencyDocument, variables, options);
export const GetLatestShortcutPracticeDocument = `
    query GetLatestShortcutPractice($layout: String!) {
  getLatestShortcutPractice {
    id
    insertedAt
    userShortcut {
      shortcut {
        id
        name
        testType
        course {
          id
          icon
          name
          public
        }
        shortcutGroup {
          icon
          name
        }
        shortcutKeys(layout: $layout) {
          key {
            code
            icon
            key
            keyCode
            name
          }
          layout
          position
          shortcutCombinationId
        }
      }
    }
  }
}
    `;
export const useGetLatestShortcutPractice = <
      TData = GetLatestShortcutPractice,
      TError = unknown
    >(
      variables: GetLatestShortcutPracticeVariables,
      options?: UseQueryOptions<GetLatestShortcutPractice, TError, TData>
    ) =>
    useQuery<GetLatestShortcutPractice, TError, TData>(
      ['GetLatestShortcutPractice', variables],
      fetcher<GetLatestShortcutPractice, GetLatestShortcutPracticeVariables>(GetLatestShortcutPracticeDocument, variables),
      options
    );
useGetLatestShortcutPractice.document = GetLatestShortcutPracticeDocument;


useGetLatestShortcutPractice.getKey = (variables: GetLatestShortcutPracticeVariables) => ['GetLatestShortcutPractice', variables];
;

useGetLatestShortcutPractice.fetcher = (variables: GetLatestShortcutPracticeVariables, options?: RequestInit['headers']) => fetcher<GetLatestShortcutPractice, GetLatestShortcutPracticeVariables>(GetLatestShortcutPracticeDocument, variables, options);
export const GetPersonalAccessTokensDocument = `
    query GetPersonalAccessTokens {
  getPersonalAccessTokens {
    id
    expires
    name
  }
}
    `;
export const useGetPersonalAccessTokens = <
      TData = GetPersonalAccessTokens,
      TError = unknown
    >(
      variables?: GetPersonalAccessTokensVariables,
      options?: UseQueryOptions<GetPersonalAccessTokens, TError, TData>
    ) =>
    useQuery<GetPersonalAccessTokens, TError, TData>(
      variables === undefined ? ['GetPersonalAccessTokens'] : ['GetPersonalAccessTokens', variables],
      fetcher<GetPersonalAccessTokens, GetPersonalAccessTokensVariables>(GetPersonalAccessTokensDocument, variables),
      options
    );
useGetPersonalAccessTokens.document = GetPersonalAccessTokensDocument;


useGetPersonalAccessTokens.getKey = (variables?: GetPersonalAccessTokensVariables) => variables === undefined ? ['GetPersonalAccessTokens'] : ['GetPersonalAccessTokens', variables];
;

useGetPersonalAccessTokens.fetcher = (variables?: GetPersonalAccessTokensVariables, options?: RequestInit['headers']) => fetcher<GetPersonalAccessTokens, GetPersonalAccessTokensVariables>(GetPersonalAccessTokensDocument, variables, options);
export const GetPracticeCountsDocument = `
    query GetPracticeCounts {
  practiceCounts {
    due
    new
    pending
    studying
  }
}
    `;
export const useGetPracticeCounts = <
      TData = GetPracticeCounts,
      TError = unknown
    >(
      variables?: GetPracticeCountsVariables,
      options?: UseQueryOptions<GetPracticeCounts, TError, TData>
    ) =>
    useQuery<GetPracticeCounts, TError, TData>(
      variables === undefined ? ['GetPracticeCounts'] : ['GetPracticeCounts', variables],
      fetcher<GetPracticeCounts, GetPracticeCountsVariables>(GetPracticeCountsDocument, variables),
      options
    );
useGetPracticeCounts.document = GetPracticeCountsDocument;


useGetPracticeCounts.getKey = (variables?: GetPracticeCountsVariables) => variables === undefined ? ['GetPracticeCounts'] : ['GetPracticeCounts', variables];
;

useGetPracticeCounts.fetcher = (variables?: GetPracticeCountsVariables, options?: RequestInit['headers']) => fetcher<GetPracticeCounts, GetPracticeCountsVariables>(GetPracticeCountsDocument, variables, options);
export const GetUserDocument = `
    query GetUser($username: String!) {
  getUser(username: $username) {
    id
    currentStreak {
      id
      days
      insertedAt
    }
    gravatar
    name
    shortcutsLearnedCount
    username
  }
}
    `;
export const useGetUser = <
      TData = GetUser,
      TError = unknown
    >(
      variables: GetUserVariables,
      options?: UseQueryOptions<GetUser, TError, TData>
    ) =>
    useQuery<GetUser, TError, TData>(
      ['GetUser', variables],
      fetcher<GetUser, GetUserVariables>(GetUserDocument, variables),
      options
    );
useGetUser.document = GetUserDocument;


useGetUser.getKey = (variables: GetUserVariables) => ['GetUser', variables];
;

useGetUser.fetcher = (variables: GetUserVariables, options?: RequestInit['headers']) => fetcher<GetUser, GetUserVariables>(GetUserDocument, variables, options);
export const GetUserCoursesDocument = `
    query GetUserCourses($username: String!) {
  getUserCourses(username: $username) {
    course {
      id
      icon
      name
      public
      slug
    }
  }
}
    `;
export const useGetUserCourses = <
      TData = GetUserCourses,
      TError = unknown
    >(
      variables: GetUserCoursesVariables,
      options?: UseQueryOptions<GetUserCourses, TError, TData>
    ) =>
    useQuery<GetUserCourses, TError, TData>(
      ['GetUserCourses', variables],
      fetcher<GetUserCourses, GetUserCoursesVariables>(GetUserCoursesDocument, variables),
      options
    );
useGetUserCourses.document = GetUserCoursesDocument;


useGetUserCourses.getKey = (variables: GetUserCoursesVariables) => ['GetUserCourses', variables];
;

useGetUserCourses.fetcher = (variables: GetUserCoursesVariables, options?: RequestInit['headers']) => fetcher<GetUserCourses, GetUserCoursesVariables>(GetUserCoursesDocument, variables, options);
export const GetUsersDocument = `
    query GetUsers($limit: Int!) {
  getUsers(limit: $limit) {
    id
    currentStreak {
      id
      days
      insertedAt
    }
    gravatar
    username
  }
}
    `;
export const useGetUsers = <
      TData = GetUsers,
      TError = unknown
    >(
      variables: GetUsersVariables,
      options?: UseQueryOptions<GetUsers, TError, TData>
    ) =>
    useQuery<GetUsers, TError, TData>(
      ['GetUsers', variables],
      fetcher<GetUsers, GetUsersVariables>(GetUsersDocument, variables),
      options
    );
useGetUsers.document = GetUsersDocument;


useGetUsers.getKey = (variables: GetUsersVariables) => ['GetUsers', variables];
;

useGetUsers.fetcher = (variables: GetUsersVariables, options?: RequestInit['headers']) => fetcher<GetUsers, GetUsersVariables>(GetUsersDocument, variables, options);
export const JoinWaitlistDocument = `
    mutation JoinWaitlist($inputs: WaitlistInputs!) {
  res: joinWaitlist(inputs: $inputs) {
    id
    convertkitSubscriptionId
    email
  }
}
    `;
export const useJoinWaitlist = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<JoinWaitlist, TError, JoinWaitlistVariables, TContext>) =>
    useMutation<JoinWaitlist, TError, JoinWaitlistVariables, TContext>(
      ['JoinWaitlist'],
      (variables?: JoinWaitlistVariables) => fetcher<JoinWaitlist, JoinWaitlistVariables>(JoinWaitlistDocument, variables)(),
      options
    );
useJoinWaitlist.fetcher = (variables: JoinWaitlistVariables, options?: RequestInit['headers']) => fetcher<JoinWaitlist, JoinWaitlistVariables>(JoinWaitlistDocument, variables, options);
export const PracticeDocument = `
    query Practice($layout: String!) {
  practice {
    practiceSession {
      id
      insertedAt
      finished
    }
    userShortcuts {
      id
      courseId
      due
      efactor
      interval
      review
      score
      studying
      testTypeOverride
      timesSeen
      shortcutId
      shortcut {
        id
        name
        testType
        course {
          id
          icon
          name
          public
          slug
        }
        shortcutGroup {
          icon
          name
        }
        shortcutKeys(layout: $layout) {
          key {
            code
            icon
            key
            keyCode
            name
          }
          layout
          position
          shortcutCombinationId
        }
      }
      userId
    }
  }
}
    `;
export const usePractice = <
      TData = Practice,
      TError = unknown
    >(
      variables: PracticeVariables,
      options?: UseQueryOptions<Practice, TError, TData>
    ) =>
    useQuery<Practice, TError, TData>(
      ['Practice', variables],
      fetcher<Practice, PracticeVariables>(PracticeDocument, variables),
      options
    );
usePractice.document = PracticeDocument;


usePractice.getKey = (variables: PracticeVariables) => ['Practice', variables];
;

usePractice.fetcher = (variables: PracticeVariables, options?: RequestInit['headers']) => fetcher<Practice, PracticeVariables>(PracticeDocument, variables, options);
export const PracticeShortcutDocument = `
    mutation practiceShortcut($inputs: PracticeInputs!) {
  res: practiceShortcut(inputs: $inputs) {
    id
    due
    efactor
    interval
    review
    score
    studying
    testTypeOverride
    timesSeen
  }
}
    `;
export const usePracticeShortcut = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PracticeShortcut, TError, PracticeShortcutVariables, TContext>) =>
    useMutation<PracticeShortcut, TError, PracticeShortcutVariables, TContext>(
      ['practiceShortcut'],
      (variables?: PracticeShortcutVariables) => fetcher<PracticeShortcut, PracticeShortcutVariables>(PracticeShortcutDocument, variables)(),
      options
    );
usePracticeShortcut.fetcher = (variables: PracticeShortcutVariables, options?: RequestInit['headers']) => fetcher<PracticeShortcut, PracticeShortcutVariables>(PracticeShortcutDocument, variables, options);
export const RemoveUserShortcutDocument = `
    mutation removeUserShortcut($id: ID!) {
  res: removeUserShortcut(id: $id) {
    id
    due
    efactor
    interval
    review
    score
    studying
    timesSeen
  }
}
    `;
export const useRemoveUserShortcut = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveUserShortcut, TError, RemoveUserShortcutVariables, TContext>) =>
    useMutation<RemoveUserShortcut, TError, RemoveUserShortcutVariables, TContext>(
      ['removeUserShortcut'],
      (variables?: RemoveUserShortcutVariables) => fetcher<RemoveUserShortcut, RemoveUserShortcutVariables>(RemoveUserShortcutDocument, variables)(),
      options
    );
useRemoveUserShortcut.fetcher = (variables: RemoveUserShortcutVariables, options?: RequestInit['headers']) => fetcher<RemoveUserShortcut, RemoveUserShortcutVariables>(RemoveUserShortcutDocument, variables, options);
export const ReportShortcutDocument = `
    mutation reportShortcut($inputs: ReportShortcutInputs!) {
  res: reportShortcut(inputs: $inputs) {
    id
  }
}
    `;
export const useReportShortcut = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ReportShortcut, TError, ReportShortcutVariables, TContext>) =>
    useMutation<ReportShortcut, TError, ReportShortcutVariables, TContext>(
      ['reportShortcut'],
      (variables?: ReportShortcutVariables) => fetcher<ReportShortcut, ReportShortcutVariables>(ReportShortcutDocument, variables)(),
      options
    );
useReportShortcut.fetcher = (variables: ReportShortcutVariables, options?: RequestInit['headers']) => fetcher<ReportShortcut, ReportShortcutVariables>(ReportShortcutDocument, variables, options);
export const RequestResetPasswordDocument = `
    mutation RequestResetPassword($inputs: RequestResetPasswordInputs!) {
  res: requestResetPassword(inputs: $inputs) {
    email
  }
}
    `;
export const useRequestResetPassword = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestResetPassword, TError, RequestResetPasswordVariables, TContext>) =>
    useMutation<RequestResetPassword, TError, RequestResetPasswordVariables, TContext>(
      ['RequestResetPassword'],
      (variables?: RequestResetPasswordVariables) => fetcher<RequestResetPassword, RequestResetPasswordVariables>(RequestResetPasswordDocument, variables)(),
      options
    );
useRequestResetPassword.fetcher = (variables: RequestResetPasswordVariables, options?: RequestInit['headers']) => fetcher<RequestResetPassword, RequestResetPasswordVariables>(RequestResetPasswordDocument, variables, options);
export const ResetPasswordDocument = `
    mutation ResetPassword($inputs: ResetPasswordInputs!) {
  res: resetPassword(inputs: $inputs) {
    success
  }
}
    `;
export const useResetPassword = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResetPassword, TError, ResetPasswordVariables, TContext>) =>
    useMutation<ResetPassword, TError, ResetPasswordVariables, TContext>(
      ['ResetPassword'],
      (variables?: ResetPasswordVariables) => fetcher<ResetPassword, ResetPasswordVariables>(ResetPasswordDocument, variables)(),
      options
    );
useResetPassword.fetcher = (variables: ResetPasswordVariables, options?: RequestInit['headers']) => fetcher<ResetPassword, ResetPasswordVariables>(ResetPasswordDocument, variables, options);
export const SigninDocument = `
    mutation Signin($inputs: SigninInputs!) {
  res: signin(inputs: $inputs) {
    data {
      token
      user {
        id
        admin
        currentStreak {
          id
          days
          insertedAt
        }
        dailyGoal
        dailyReminderHour
        dailyReminderSend
        email
        gravatar
        name
        onboardingState
        shortcutsLearnedCount
        subscriptionActive
        timezone
        username
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export const useSignin = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Signin, TError, SigninVariables, TContext>) =>
    useMutation<Signin, TError, SigninVariables, TContext>(
      ['Signin'],
      (variables?: SigninVariables) => fetcher<Signin, SigninVariables>(SigninDocument, variables)(),
      options
    );
useSignin.fetcher = (variables: SigninVariables, options?: RequestInit['headers']) => fetcher<Signin, SigninVariables>(SigninDocument, variables, options);
export const SignupDocument = `
    mutation Signup($inputs: RegistrationInputs!) {
  res: signup(inputs: $inputs) {
    data {
      token
      user {
        id
        admin
        currentStreak {
          id
          days
          insertedAt
        }
        dailyGoal
        dailyReminderHour
        dailyReminderSend
        email
        gravatar
        name
        onboardingState
        shortcutsLearnedCount
        subscriptionActive
        timezone
        username
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export const useSignup = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Signup, TError, SignupVariables, TContext>) =>
    useMutation<Signup, TError, SignupVariables, TContext>(
      ['Signup'],
      (variables?: SignupVariables) => fetcher<Signup, SignupVariables>(SignupDocument, variables)(),
      options
    );
useSignup.fetcher = (variables: SignupVariables, options?: RequestInit['headers']) => fetcher<Signup, SignupVariables>(SignupDocument, variables, options);
export const StartCheckoutSessionDocument = `
    mutation startCheckoutSession($inputs: StartCheckoutSessionInputs!) {
  res: startCheckoutSession(inputs: $inputs) {
    url
  }
}
    `;
export const useStartCheckoutSession = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<StartCheckoutSession, TError, StartCheckoutSessionVariables, TContext>) =>
    useMutation<StartCheckoutSession, TError, StartCheckoutSessionVariables, TContext>(
      ['startCheckoutSession'],
      (variables?: StartCheckoutSessionVariables) => fetcher<StartCheckoutSession, StartCheckoutSessionVariables>(StartCheckoutSessionDocument, variables)(),
      options
    );
useStartCheckoutSession.fetcher = (variables: StartCheckoutSessionVariables, options?: RequestInit['headers']) => fetcher<StartCheckoutSession, StartCheckoutSessionVariables>(StartCheckoutSessionDocument, variables, options);
export const UnsubscribeDocument = `
    mutation unsubscribe($inputs: UnsubscribeInputs!) {
  res: unsubscribe(inputs: $inputs) {
    success
  }
}
    `;
export const useUnsubscribe = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Unsubscribe, TError, UnsubscribeVariables, TContext>) =>
    useMutation<Unsubscribe, TError, UnsubscribeVariables, TContext>(
      ['unsubscribe'],
      (variables?: UnsubscribeVariables) => fetcher<Unsubscribe, UnsubscribeVariables>(UnsubscribeDocument, variables)(),
      options
    );
useUnsubscribe.fetcher = (variables: UnsubscribeVariables, options?: RequestInit['headers']) => fetcher<Unsubscribe, UnsubscribeVariables>(UnsubscribeDocument, variables, options);
export const UnsubscribedCoursesDocument = `
    query UnsubscribedCourses {
  unsubscribedCourses {
    id
    icon
    name
    public
    slug
  }
}
    `;
export const useUnsubscribedCourses = <
      TData = UnsubscribedCourses,
      TError = unknown
    >(
      variables?: UnsubscribedCoursesVariables,
      options?: UseQueryOptions<UnsubscribedCourses, TError, TData>
    ) =>
    useQuery<UnsubscribedCourses, TError, TData>(
      variables === undefined ? ['UnsubscribedCourses'] : ['UnsubscribedCourses', variables],
      fetcher<UnsubscribedCourses, UnsubscribedCoursesVariables>(UnsubscribedCoursesDocument, variables),
      options
    );
useUnsubscribedCourses.document = UnsubscribedCoursesDocument;


useUnsubscribedCourses.getKey = (variables?: UnsubscribedCoursesVariables) => variables === undefined ? ['UnsubscribedCourses'] : ['UnsubscribedCourses', variables];
;

useUnsubscribedCourses.fetcher = (variables?: UnsubscribedCoursesVariables, options?: RequestInit['headers']) => fetcher<UnsubscribedCourses, UnsubscribedCoursesVariables>(UnsubscribedCoursesDocument, variables, options);
export const UpdateDailyGoalDocument = `
    mutation updateDailyGoal($inputs: DailyGoalInputs!) {
  res: updateDailyGoal(inputs: $inputs) {
    id
    dailyGoal
    dailyReminderHour
    dailyReminderSend
    email
    gravatar
    name
    onboardingState
    timezone
    username
  }
}
    `;
export const useUpdateDailyGoal = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDailyGoal, TError, UpdateDailyGoalVariables, TContext>) =>
    useMutation<UpdateDailyGoal, TError, UpdateDailyGoalVariables, TContext>(
      ['updateDailyGoal'],
      (variables?: UpdateDailyGoalVariables) => fetcher<UpdateDailyGoal, UpdateDailyGoalVariables>(UpdateDailyGoalDocument, variables)(),
      options
    );
useUpdateDailyGoal.fetcher = (variables: UpdateDailyGoalVariables, options?: RequestInit['headers']) => fetcher<UpdateDailyGoal, UpdateDailyGoalVariables>(UpdateDailyGoalDocument, variables, options);
export const UpdateDailyReminderDocument = `
    mutation updateDailyReminder($inputs: DailyReminderInputs!) {
  res: updateDailyReminder(inputs: $inputs) {
    id
    dailyGoal
    dailyReminderHour
    dailyReminderSend
    email
    gravatar
    name
    onboardingState
    timezone
    username
  }
}
    `;
export const useUpdateDailyReminder = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDailyReminder, TError, UpdateDailyReminderVariables, TContext>) =>
    useMutation<UpdateDailyReminder, TError, UpdateDailyReminderVariables, TContext>(
      ['updateDailyReminder'],
      (variables?: UpdateDailyReminderVariables) => fetcher<UpdateDailyReminder, UpdateDailyReminderVariables>(UpdateDailyReminderDocument, variables)(),
      options
    );
useUpdateDailyReminder.fetcher = (variables: UpdateDailyReminderVariables, options?: RequestInit['headers']) => fetcher<UpdateDailyReminder, UpdateDailyReminderVariables>(UpdateDailyReminderDocument, variables, options);
export const UpdateNameDocument = `
    mutation updateName($name: String) {
  res: updateName(name: $name) {
    id
    dailyGoal
    dailyReminderHour
    dailyReminderSend
    email
    gravatar
    name
    onboardingState
    timezone
    username
  }
}
    `;
export const useUpdateName = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateName, TError, UpdateNameVariables, TContext>) =>
    useMutation<UpdateName, TError, UpdateNameVariables, TContext>(
      ['updateName'],
      (variables?: UpdateNameVariables) => fetcher<UpdateName, UpdateNameVariables>(UpdateNameDocument, variables)(),
      options
    );
useUpdateName.fetcher = (variables?: UpdateNameVariables, options?: RequestInit['headers']) => fetcher<UpdateName, UpdateNameVariables>(UpdateNameDocument, variables, options);
export const UpdateNamesDocument = `
    mutation updateNames($inputs: NamesInputs!) {
  res: updateNames(inputs: $inputs) {
    id
    dailyGoal
    dailyReminderHour
    dailyReminderSend
    email
    gravatar
    name
    onboardingState
    timezone
    username
  }
}
    `;
export const useUpdateNames = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateNames, TError, UpdateNamesVariables, TContext>) =>
    useMutation<UpdateNames, TError, UpdateNamesVariables, TContext>(
      ['updateNames'],
      (variables?: UpdateNamesVariables) => fetcher<UpdateNames, UpdateNamesVariables>(UpdateNamesDocument, variables)(),
      options
    );
useUpdateNames.fetcher = (variables: UpdateNamesVariables, options?: RequestInit['headers']) => fetcher<UpdateNames, UpdateNamesVariables>(UpdateNamesDocument, variables, options);
export const UpdateUserShortcutTestTypeDocument = `
    mutation updateUserShortcutTestType($inputs: UserShortcutUpdateTestTypeInputs!) {
  res: updateUserShortcutTestType(inputs: $inputs) {
    id
    due
    efactor
    interval
    review
    score
    studying
    testTypeOverride
    timesSeen
  }
}
    `;
export const useUpdateUserShortcutTestType = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserShortcutTestType, TError, UpdateUserShortcutTestTypeVariables, TContext>) =>
    useMutation<UpdateUserShortcutTestType, TError, UpdateUserShortcutTestTypeVariables, TContext>(
      ['updateUserShortcutTestType'],
      (variables?: UpdateUserShortcutTestTypeVariables) => fetcher<UpdateUserShortcutTestType, UpdateUserShortcutTestTypeVariables>(UpdateUserShortcutTestTypeDocument, variables)(),
      options
    );
useUpdateUserShortcutTestType.fetcher = (variables: UpdateUserShortcutTestTypeVariables, options?: RequestInit['headers']) => fetcher<UpdateUserShortcutTestType, UpdateUserShortcutTestTypeVariables>(UpdateUserShortcutTestTypeDocument, variables, options);
export const UpdateUsernameDocument = `
    mutation updateUsername($username: String!) {
  res: updateUsername(username: $username) {
    id
    dailyGoal
    dailyReminderHour
    dailyReminderSend
    email
    gravatar
    name
    onboardingState
    timezone
    username
  }
}
    `;
export const useUpdateUsername = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUsername, TError, UpdateUsernameVariables, TContext>) =>
    useMutation<UpdateUsername, TError, UpdateUsernameVariables, TContext>(
      ['updateUsername'],
      (variables?: UpdateUsernameVariables) => fetcher<UpdateUsername, UpdateUsernameVariables>(UpdateUsernameDocument, variables)(),
      options
    );
useUpdateUsername.fetcher = (variables: UpdateUsernameVariables, options?: RequestInit['headers']) => fetcher<UpdateUsername, UpdateUsernameVariables>(UpdateUsernameDocument, variables, options);
export const UserCoursesDocument = `
    query UserCourses {
  userCourses {
    course {
      id
      icon
      name
      public
      slug
    }
    user {
      id
    }
  }
}
    `;
export const useUserCourses = <
      TData = UserCourses,
      TError = unknown
    >(
      variables?: UserCoursesVariables,
      options?: UseQueryOptions<UserCourses, TError, TData>
    ) =>
    useQuery<UserCourses, TError, TData>(
      variables === undefined ? ['UserCourses'] : ['UserCourses', variables],
      fetcher<UserCourses, UserCoursesVariables>(UserCoursesDocument, variables),
      options
    );
useUserCourses.document = UserCoursesDocument;


useUserCourses.getKey = (variables?: UserCoursesVariables) => variables === undefined ? ['UserCourses'] : ['UserCourses', variables];
;

useUserCourses.fetcher = (variables?: UserCoursesVariables, options?: RequestInit['headers']) => fetcher<UserCourses, UserCoursesVariables>(UserCoursesDocument, variables, options);