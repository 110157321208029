import { useEffect, useRef } from 'react'
import type { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import SEO from '../../next-seo.config'
import { DefaultSeo } from 'next-seo'
import Script from 'next/script'
import * as snippet from '@segment/snippet'
import { Toaster } from 'react-hot-toast'
import * as LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

import '../lib/i18n'

import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
import '../styles/globals.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { SessionProvider } from '@contexts/SessionContext'
import ProtectedRoute from '@components/ProtectedRoute'
import { OnboardingProvider } from '@contexts/OnboardingContext'

function renderSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  }

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts)
  }

  return snippet.min(opts)
}

if (typeof window !== 'undefined') {
  LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_WRITE_KEY)
  setupLogRocketReact(LogRocket)
}

const App: NextPage<AppPropsWithLayout> = ({
  Component,
  pageProps,
  router,
}) => {
  useEffect(() => {
    const handleRouteChange = (url) => {
      window.analytics.page(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  const queryClientRef = useRef<QueryClient>()

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient()
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <SessionProvider>
          <ProtectedRoute router={router}>
            <OnboardingProvider>
              <DefaultSeo {...SEO} />
              <Script
                id="segment-script"
                dangerouslySetInnerHTML={{ __html: renderSnippet() }}
              />
              <div>
                <Toaster />
              </div>
              {getLayout(
                <Component
                  {...pageProps}
                  key={router.asPath}
                  query={router.query}
                />,
              )}
            </OnboardingProvider>
          </ProtectedRoute>
        </SessionProvider>
        <ReactQueryDevtools initialIsOpen />
      </Hydrate>
    </QueryClientProvider>
  )
}

export default App
