import { useContext } from 'react'
import { isServer } from '@utils/helpers'
import { LOGIN_PAGE, unprotectedRoutes } from 'constants/routes'
import { SessionContext } from '@contexts/SessionContext'

const ProtectedRoute = ({ router, children }) => {
  //Identify authenticated user
  const { isAuthenticated } = useContext(SessionContext)

  /**
   * @var pathIsProtected Checks if path exists in the unprotectedRoutes routes array
   */
  const pathIsProtected = !unprotectedRoutes.includes(router.pathname)

  if (!isServer && !isAuthenticated && pathIsProtected) {
    router.replace(LOGIN_PAGE)
  }

  return children
}

export default ProtectedRoute
