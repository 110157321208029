import * as React from 'react'
import { useRouter } from 'next/router'
import * as LogRocket from 'logrocket'

import { Session, useCurrentUser } from '@hooks/generated'
import { isServer } from '@utils/helpers'
import {
  getToken,
  isAuthenticated as sessionIsAuthenticated,
  removeToken,
  storeToken,
} from 'services/session'

type PickedSession = Omit<Session, '__typename'>

type Context = {
  isAuthenticated: boolean
  session: PickedSession | undefined
  setAuth: React.Dispatch<React.SetStateAction<PickedSession | undefined>>
  setSession: React.Dispatch<React.SetStateAction<PickedSession | undefined>>
}

export const SessionContext = React.createContext<Context | undefined>(
  undefined,
)

interface SessionProviderProps {
  children?: React.ReactNode
}

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}) => {
  const { asPath } = useRouter()
  const { data: user } = useCurrentUser()
  const [session, setSession] = React.useState<PickedSession | undefined>({
    token: getToken(),
    user: user?.me,
  })
  const [tokenSent, setTokenSent] = React.useState<boolean>(false)
  const [signedOutSent, setSignedOutSent] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!isServer) {
      // We can look for a token.
      const token = getToken()
      if (token) {
        if (user && user.me) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const traits = (({ id, __typename, ...rest }) => rest)(user.me)
          window.analytics.identify(user.me.id, traits)
          const {
            currentStreak, // eslint-disable-line @typescript-eslint/no-unused-vars
            ...rest
          } = traits
          LogRocket.identify(user.me.id, Object.freeze(rest))
        }
        // The final guard here protects against an infinite loop.
        if (user && user.me && !session.user) {
          // Situation occurred where session is being set as undefined on
          // initial render because we cannot get access to the browser from
          // the server, yet there is a token in the browser and the user
          // query did execute as it should have.
          setSession({
            token,
            user: user.me,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, session, user])

  const setAuth = (data) => {
    if (data) {
      storeToken(data.token)
      setSession(data)
    } else {
      removeToken()
      setSession(undefined)
    }
  }

  React.useEffect(() => {
    if (session && session.token && !tokenSent) {
      window.postMessage({ khauth: session.token })
      setTokenSent(true)
      setSignedOutSent(false)
    }

    if (!signedOutSent && (!session || !session.token)) {
      window.postMessage({ signedOut: true })
      setTokenSent(false)
      setSignedOutSent(true)
    }
  }, [session, setTokenSent, signedOutSent, tokenSent])

  return (
    <SessionContext.Provider
      value={{
        isAuthenticated: sessionIsAuthenticated(),
        session,
        setAuth,
        setSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}
