import * as React from 'react'
import { isServer } from '@utils/helpers'
import { useCurrentUser, User } from '@hooks/generated'
import { useRouter } from 'next/router'
import {
  allowedRoutesWhenOnboarding,
  ONBOARDING_PAGE,
} from '../constants/routes'

type Context = {
  isOnboarding: boolean
  isSubscriptionActive: boolean
  onboardingStep: string | undefined
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>
  STEPS: string[]
  LAST_STEP: string
}

export const OnboardingContext = React.createContext<Context | undefined>(
  undefined,
)

interface OnboardingProviderProps {
  children?: React.ReactNode
}

export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({
  children,
}) => {
  const { pathname, push } = useRouter()
  const { data } = useCurrentUser()
  const [user, setUser] = React.useState(undefined)
  const [isOnboarding, setIsOnboarding] = React.useState(undefined)
  const [onboardingStep, setOnboardingStep] = React.useState(undefined)
  const [isSubscriptionActive, setIsSubscriptionActive] =
    React.useState(undefined)

  const STEPS = [
    'signed_up',
    'picked_username',
    'picked_daily_goal',
    'picked_reminder_time',
  ]

  const LAST_STEP = STEPS[STEPS.length - 1]

  React.useEffect(() => {
    if (!isServer) {
      if (data && data.me) {
        setUser(data.me)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  React.useEffect(() => {
    if (user) {
      setIsSubscriptionActive(user.subscriptionActive)
      setIsOnboarding(user.onboardingState !== LAST_STEP)
      setOnboardingStep(user.onboardingState)
    }
  }, [LAST_STEP, user])

  React.useEffect(() => {
    if (isOnboarding) {
      if (!allowedRoutesWhenOnboarding.includes(pathname)) {
        push(ONBOARDING_PAGE)
      }
    }
  }, [isOnboarding, LAST_STEP, onboardingStep, pathname, push])

  return (
    <OnboardingContext.Provider
      value={{
        isOnboarding,
        isSubscriptionActive,
        onboardingStep,
        setUser,
        STEPS,
        LAST_STEP,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}
